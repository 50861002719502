import { useJSONFetcher, JSONFetcherOptions } from "@outplayed/json-fetcher";
import { getApiRoot } from "../../api-helpers";
import { useUGGPatches } from "../patches";
import { useUGGApiVersions } from "../ugg-api-versions";
import { PrimaryRoles } from "@ugg/shared/interfaces/champions/primary-roles.interface";

function useChampionRolesURL() {
  const patches = useUGGPatches();
  const { data: version } = useUGGApiVersions({
    apiKey: "primary_roles",
    patch: patches?.data?.[0],
    skip: !patches.data,
  });

  if (patches?.data?.[0] && version) {
    return `${getApiRoot()}/primary_roles/${patches?.data?.[0]}/${version}.json`;
  } else {
    return null;
  }
}

export function usePrimaryRoles(options?: JSONFetcherOptions) {
  const { ssr = false, skip = false } = options || {};
  const primaryRolesURL = useChampionRolesURL();
  return useJSONFetcher<PrimaryRoles>(primaryRolesURL || "", {
    skip: skip || !primaryRolesURL,
    ssr,
  });
}
