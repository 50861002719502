import { round } from "@ugg/shared/utils/math";
import { RoleC, RoleN } from "@ugg/shared/utils/role-helpers";
import { RegionC, RegionN } from "@ugg/shared/utils/region-helpers";
import { RankC, RankN } from "@ugg/shared/utils/rank-helpers";
import {
  StatShards,
  Runes,
  SummonerSpells,
  StartingItems,
  CoreItems,
  Skills,
  EvolvedSkills,
  Item,
  BuildWins,
  BuildMatches,
  BuildWarning,
  LastUpdated,
  Overview,
  Multibuilds,
} from "@ugg/shared/interfaces/champions/overview.interface";

import { getKey, getData } from "./common";

export interface ItemOption {
  wins: Item[1];
  matches: Item[2];
  win_rate: number;
  id: Item[0];
}

export interface PrismaticItemOption {
  wins: Item[1];
  matches: Item[2];
  avgPlacement: number;
  firstRate: number;
  top4Rate: number;
  id: Item[0];
  pickRate: number;
}

export interface OverviewInfo {
  stat_shards: {
    matches: StatShards[0];
    wins: StatShards[1];
    win_rate: number;
    active_shards: number[];
  } | null;
  rec_runes: {
    matches: Runes[0];
    wins: Runes[1];
    win_rate: number;
    primary_style: Runes[2];
    sub_style: Runes[3];
    active_perks: Runes[4];
  } | null;
  rec_summoner_spells: {
    matches: SummonerSpells[0];
    wins: SummonerSpells[1];
    win_rate: number;
    ids: SummonerSpells[2];
  } | null;
  rec_starting_items: {
    matches: StartingItems[0];
    wins: StartingItems[1];
    win_rate: number;
    ids: StartingItems[2];
  } | null;
  rec_core_items: {
    matches: CoreItems[0];
    wins: CoreItems[1];
    win_rate: number;
    ids: CoreItems[2];
  } | null;
  rec_skills: {
    slots: string[];
  } | null;
  rec_skill_path: {
    matches: Skills[0];
    wins: Skills[1];
    win_rate: number;
    slots: Skills[2];
  } | null;
  rec_skill_evolve_path: {
    matches: EvolvedSkills[0];
    wins: EvolvedSkills[1];
    win_rate: number;
    slots: string[];
  } | null;
  item_options_1: ItemOption[] | null;
  item_options_2: ItemOption[] | null;
  item_options_3: ItemOption[] | null;
  consumable_options: ItemOption[] | null;
  prismatic_options: PrismaticItemOption[];
  arena_synergies: {
    championId: number;
    avgPlacement: number;
    firstRate: number;
    top2Rate: number;
    pickRate: number;
    matches: number;
  }[];
  arena_augments: {
    augmentId: number;
    pickRate: number;
    matches: number;
  }[];
  wins: BuildWins;
  matches: BuildMatches;
  win_rate: number | null;
  build_warning: BuildWarning;
  last_updated: LastUpdated;
}

export interface ExtendedOverviewInfo extends OverviewInfo {
  type: string;
}

export function getOverview(
  data: ReturnType<typeof cleanOverviewData>,
  params: {
    queueType: string;
    region: string;
    rank?: string;
    role?: string;
  },
) {
  return getData(data, params);
}

export function cleanOverviewData(data: Overview) {
  const cleanData: Record<string, OverviewInfo> = {};
  const regionKeys: RegionN[] = Object.keys(data || {}).map(Number);
  for (const regionId of regionKeys) {
    const regionData = data[regionId];
    const rankKeys: RankN[] = Object.keys(regionData || {}).map(Number);

    for (const rankId of rankKeys) {
      const rankData = regionData[rankId];
      const roleKeys: RoleN[] = Object.keys(rankData || {}).map(Number);

      for (const roleId of roleKeys) {
        const roleData = rankData[roleId];
        const buildData = roleData[0];
        const last_updated = roleData[1];

        const [
          runes,
          summonerSpells,
          startingItems,
          coreItems,
          skillPath,
          itemOptions,
          championMatches,
          build_warning,
          statShards,
          skillEvolvePath,
          arenaSynergies,
          arenaAugments,
        ] = buildData;

        const wins = championMatches[0];
        const matches = championMatches[1];
        const win_rate = wins >= 0 && matches > 0 ? round((wins / matches) * 100, 2) : null;

        // Stat Shards
        const stat_shards =
          !statShards || statShards.length !== 3
            ? null
            : {
                matches: statShards[0],
                wins: statShards[1],
                win_rate: round((statShards[1] / statShards[0]) * 100, 2),
                active_shards: statShards[2].map((val) => Number(val)) || [],
              };

        // Runes
        const rec_runes =
          !runes || runes.length !== 5
            ? null
            : {
                matches: runes[0],
                wins: runes[1],
                win_rate: round((runes[1] / runes[0]) * 100, 2),
                primary_style: runes[2],
                sub_style: runes[3],
                active_perks: runes[4],
              };

        // Summoner Spells
        const rec_summoner_spells =
          !summonerSpells || summonerSpells.length !== 3
            ? null
            : {
                matches: summonerSpells[0],
                wins: summonerSpells[1],
                win_rate: round((summonerSpells[1] / summonerSpells[0]) * 100, 2),
                ids: summonerSpells[2],
              };

        // Starting Items
        const rec_starting_items =
          !startingItems || startingItems.length !== 3
            ? null
            : {
                matches: startingItems[0],
                wins: startingItems[1],
                win_rate: round((startingItems[1] / startingItems[0]) * 100, 2),
                ids: startingItems[2],
              };

        // Core Items
        const rec_core_items =
          !coreItems || coreItems.length !== 3
            ? null
            : {
                matches: coreItems[0],
                wins: coreItems[1],
                win_rate: round((coreItems[1] / coreItems[0]) * 100, 2),
                ids: coreItems[2],
              };

        // Skill Priority
        const rec_skills =
          !skillPath || skillPath.length !== 4
            ? null
            : {
                slots: skillPath[3].split(""),
              };

        // Skill Path
        const rec_skill_path =
          !skillPath || skillPath.length !== 4
            ? null
            : {
                matches: skillPath[0],
                wins: skillPath[1],
                win_rate: round((skillPath[1] / skillPath[0]) * 100, 2),
                slots: skillPath[2],
              };

        // Evolved Skill Path
        const rec_skill_evolve_path =
          !skillEvolvePath || skillEvolvePath.length !== 3
            ? null
            : {
                matches: skillEvolvePath[0],
                wins: skillEvolvePath[1],
                win_rate: round((skillEvolvePath[1] / skillEvolvePath[0]) * 100, 2),
                slots: (skillEvolvePath[2] || "").split(""),
              };

        // Item Options
        const [itemOptions1, itemOptions2, itemOptions3, consumableOptions, prismaticOption] = itemOptions;

        const [item_options_1, item_options_2, item_options_3, consumable_options] = [
          itemOptions1,
          itemOptions2,
          itemOptions3,
          consumableOptions,
        ].map((options) => {
          if (!options || options.length === 0) return null;

          return options.map((item) => {
            return {
              wins: item[1],
              matches: item[2],
              win_rate: round((item[1] / item[2]) * 100, 2),
              id: item[0],
            };
          });
        });

        const prismatic_options = (prismaticOption || []).map((item) => {
          return {
            wins: item[3],
            matches: item[4],
            avgPlacement: round(item[2] / item[4], 2),
            top4Rate: round((item[3] / item[4]) * 100, 2),
            id: item[0],
            firstRate: round((item[1] / item[4]) * 100, 2),
            pickRate: round(((item[4] || 0) / matches) * 100, 2),
          };
        });

        // Arena Synergies
        const arena_synergies = (arenaSynergies || []).map((champion) => {
          return {
            championId: champion[0],
            avgPlacement: round((champion[4] || 0) / champion[2], 2),
            firstRate: round(((champion[3] || 0) / champion[2]) * 100, 2),
            top2Rate: round(((champion[1] || 0) / champion[2]) * 100, 2),
            pickRate: round(((champion[2] || 0) / matches) * 100, 2),
            matches: champion[2] || 0,
          };
        });

        // Arena Augments
        const arena_augments = (arenaAugments || []).map((augment) => {
          return {
            augmentId: augment[0],
            pickRate: round((augment[2] / matches) * 100, 2),
            matches: augment[2],
          };
        });

        const overview: OverviewInfo = {
          stat_shards,
          rec_runes,
          rec_summoner_spells,
          rec_starting_items,
          rec_core_items,
          rec_skills,
          rec_skill_path,
          rec_skill_evolve_path,
          item_options_1,
          item_options_2,
          item_options_3,
          prismatic_options,
          consumable_options,
          wins,
          matches,
          win_rate,
          build_warning,
          last_updated,
          arena_synergies,
          arena_augments,
        };

        const key = getKey(RegionC.convertToString(regionId), RankC.convertToString(rankId), RoleC.convertToString(roleId));

        cleanData[key] = overview;
      }
    }
  }

  return cleanData;
}
