import classNames from "classnames";
import { useGlobal } from "reactn";
import { ChampionSkill, ChampionSkillProps } from "@outplayed/tooltips";

interface ChampionSkillWithLabelProps extends ChampionSkillProps {
  className?: string;
  label: string;
  labelPosition: "bottomRight" | "bottomCenter";
}

export default function ChampionSkillWithLabel(props: ChampionSkillWithLabelProps) {
  const [language] = useGlobal("language");
  // same props as ChampionSkills
  const {
    className,
    label,
    labelPosition, // bottomRight, bottomCenter
    ...tooltipProps
  } = props;

  const skillLabelClassNames = classNames("skill-label", {
    "bottom-right": labelPosition === "bottomRight",
    "bottom-center": labelPosition === "bottomCenter",
  });
  return (
    <div className={classNames("champion-skill-with-label", className)}>
      <ChampionSkill {...tooltipProps} language={language} webp />
      <div className={skillLabelClassNames}>{label}</div>
    </div>
  );
}
