import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { usePrimaryRoles } from "./primary-roles";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { CHAMPION_PAGES, SPECIAL_CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";
import { buildQueryParams, parseQueryString } from "@ugg/shared/utils/url-query-params";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { RoleS, RoleC } from "@ugg/shared/utils/role-helpers";

export const champion_transform_map: Record<number, number[]> = {
  141: [100141, 200141],
};

export function useValidatedChampionProfileParams(
  championId: number,
  page: CHAMPION_PAGES | SPECIAL_CHAMPION_PAGES | undefined,
  options?: { keepDefaultParams?: boolean; ssr?: boolean },
) {
  const { keepDefaultParams = false, ssr = false } = options || {};
  const { useBackupChampions } = getRiotAssetsContext();
  const backupChampions = useBackupChampions();
  const location = useLocation<{ persistRole: boolean }>();
  const queryParams = useQueryString();
  const validateQueryParams = useValidateQueryParams(validParams);

  const { data: primaryRoles } = usePrimaryRoles({ ssr });
  const validatedQueryParams = validateQueryParams(page, queryParams, keepDefaultParams);
  const validPageParams = page && validParams[page];
  const oldParams = location.search.charAt(0) === "?" ? location.search.substring(1) : location.search;

  if (!keepDefaultParams) {
    if (
      validatedQueryParams?.queueType !== QueueTypeS.RANKED_SOLO &&
      validatedQueryParams?.queueType !== QueueTypeS.RANKED_FLEX
    ) {
      delete validatedQueryParams.opp;
    }

    // Remove default role param
    if (!location.state?.persistRole) {
      const primaryRole =
        primaryRoles && championId in primaryRoles
          ? RoleC.convertToString(primaryRoles[championId][0])
          : backupChampions[championId] && backupChampions[championId].primaryRole
          ? backupChampions[championId].primaryRole
          : RoleS.MIDDLE;

      const isNexusBlitz =
        validatedQueryParams.queueType === QueueTypeS.NEXUS_BLITZ || page === SPECIAL_CHAMPION_PAGES.OVERVIEW_NEXUS_BLITZ;
      const defaultRole = validPageParams && "role" in validPageParams.default && validPageParams.default.role;
      if ((!isNexusBlitz && !defaultRole && validatedQueryParams.role === primaryRole) || primaryRole === defaultRole) {
        delete validatedQueryParams.role;
      }
    }
  }

  const newParams = buildQueryParams(validatedQueryParams);
  const paramsToReturn = oldParams !== newParams ? newParams : oldParams;

  return useMemo(() => {
    return {
      pathname: location.pathname,
      search: paramsToReturn,
      validatedParams: parseQueryString(paramsToReturn),
    };
  }, [location.pathname, oldParams, newParams]);
}

export function useCurrentRole(
  championId: number,
  page: CHAMPION_PAGES | SPECIAL_CHAMPION_PAGES | undefined,
  options?: { ssr: boolean },
) {
  const { ssr = false } = options || {};
  const { useBackupChampions } = getRiotAssetsContext();
  const backupChampions = useBackupChampions();
  const queryParams = useQueryString();
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(page, queryParams, true);
  const { data: primaryRoles } = usePrimaryRoles({ ssr });

  if (validatedQueryParams.role) {
    return validatedQueryParams.role;
  }

  if (!primaryRoles) {
    return undefined;
  } else {
    const primaryRole =
      championId in primaryRoles
        ? RoleC.convertToString(primaryRoles[championId][0])
        : backupChampions[championId] && backupChampions[championId].primaryRole
        ? backupChampions[championId].primaryRole
        : RoleS.MIDDLE;

    return primaryRole;
  }
}
