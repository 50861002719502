import { normalizeRole, RoleS } from "@ugg/shared/utils/role-helpers";
import { RankS } from "@ugg/shared/utils/rank-helpers";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";

export function getKey(region: string, rank: string, role: string) {
  return `${region}_${rank}_${role}`;
}

export function getData<T extends Record<string, any>>(
  data: T,
  params: {
    queueType: string;
    region: string;
    rank?: string;
    role?: string;
  },
): T[keyof T] | null {
  let { queueType, region, rank = "", role = "" } = params;

  if (!data) {
    return null;
  }

  if (
    queueType === QueueTypeS.ARAM ||
    queueType === QueueTypeS.ARURF ||
    queueType === QueueTypeS.URF ||
    queueType === QueueTypeS.PICK_URF ||
    queueType === QueueTypeS.ARENA
  ) {
    rank = RankS.OVERALL;
    role = RoleS.NONE;
  } else if (
    queueType === QueueTypeS.NORMAL_BLIND ||
    queueType === QueueTypeS.NORMAL_DRAFT ||
    queueType === QueueTypeS.QUICKPLAY ||
    queueType === QueueTypeS.ONE_FOR_ALL ||
    queueType === QueueTypeS.NEXUS_BLITZ
  ) {
    rank = RankS.OVERALL;
    role = normalizeRole(role, RoleS.ALL);
  } else {
    role = normalizeRole(role, RoleS.ALL);
  }

  return data[getKey(region, rank, role)];
}
